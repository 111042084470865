import React, { Component } from "react";
import ThreeColGrid from "../elements/ThreeColGrid/ThreeColGrid";
import Banner from "../elements/Banner/Banner";
import "./Reviews.css";

class Reviews extends Component {
  state = {
    reviewsData: null,
  };

  // Fetching Complete Reviews List
  componentDidMount() {
    fetch("https://nhrvrentalapi.nhrvrental.com/get-reviews", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        this.setState({
          reviewsData: findresponse,
        });
      });
  }

  render() {
    return (
      <div className="bg-image-wrapper">
        <div className="overlay"></div>
        <img className="bg-image" src="./images/reviews-bg.jpg" />
        <div className="bg-image-content boxed-content">
          <ThreeColGrid
            title="CUSTOMER REVIEWS"
            reviewsData={this.state.reviewsData}
          />
          <Banner footer={true}/>
        </div>
      </div>
    );
  }
}

export default Reviews;
