import React, { useState } from "react";

import CalendarFields from "../CalendarFields/CalendarFields";
import "./Delivery.css";

const Delivery = (props) => {
  const [destination, setDestination] = useState(false);
  const [calculatingCost, setCalculatingCost] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState(false);

  var formtitle = "";
  var buttontitle = "";

  var distancetext = null;
  var distancemiles = null;
  var durationtext = null;
  var durationhours = null;
  var hourlyrate = 25;
  var costpermile = .90;
  var deliveryResult = null;
  var costResult = null;

  if (props.payment) {
    formtitle = "DELIVERY ADDRESS";
    buttontitle = "PRICE DELIVERY";
  }
  else {
    formtitle = "CALCULATE DELIVERY COST";
    buttontitle = "CALCULATE COST";
  }

  function changeDeliveryCost(deliveryCostInfo, destination, deliveryCost) 
  {
    if (props.onChangeDeliveryCost != null) 
    {
      props.onChangeDeliveryCost(destination, deliveryCost)
    }

    setDeliveryMessage(deliveryCostInfo);
  }

  const getDeliveryCost = () => {
    if (calculatingCost || destination == false || destination == '') {
      return;
    }

    setCalculatingCost(true);

    fetch("https://nhrvrentalapi.nhrvrental.com/get-delivery-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        destination: destination,
      }),
    })
      .then((r) => {
        return r.json();
      })
      .then((res) => {
        setCalculatingCost(false);

        if (res.response) 
        {
          if (res.response.rows[0].elements[0].status == 'OK') 
          {
            var destination = res.response.destination_addresses[0];

            distancetext = res.response.rows[0].elements[0].distance.text;
            distancemiles = res.response.rows[0].elements[0].distance.value / 1609.34;

            durationtext = res.response.rows[0].elements[0].duration.text;
            durationhours = res.response.rows[0].elements[0].duration.value / 3600;
            
            costResult = (hourlyrate * (4 * durationhours)) + (costpermile * (4 * distancemiles));
            costResult = costResult.toFixed(2);

            deliveryResult = 'Distance from NH RV Rental to ' + destination + ' is ' + distancetext + ' and ' + durationtext + '.  Delivery Cost is $' + costResult + '.';

            if (props.payment) 
            {
              deliveryResult = deliveryResult + '  Please select the Truck Delivery check box above to add Truck Delivery to your reservation.';
            }
            
            changeDeliveryCost(deliveryResult, destination, costResult);
          }
        }
        else 
        {
          changeDeliveryCost('There was an error calculating delivery cost.  Please try again later', 0);
        }
      });
  };

  return (
    <div className="delivery" >
      <div className="delivery-content">
        <h1 className="delivery-title">{formtitle}</h1>
        <input
          id="destination"
          name="destination"
          placeholder="Enter Delivery Address"
          value={destination ? destination : ""}
          onChange={(event) => setDestination(event.target.value)}
        ></input>
        {<h2>{deliveryMessage}</h2>}
        <div className="calc-btn" onClick={getDeliveryCost}>
          {buttontitle}
        </div>
      </div>
    </div>
  );
};

export default Delivery;