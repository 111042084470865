import React, { Component } from "react";
import "./App.css";
import Header from "../elements/Header/Header";
import Home from "../Home/Home";
import Footer from "../elements/Footer/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RVList from "../RV-List/RV-List";
import RVDetails from "../RV-Details/RV-Details";
import Reviews from "../Reviews/Reviews";
import Contact from "../Contact/Contact";
import Amenities from "../Amenities/Amenities";
import Policies from "../Policies/Policies";
import Payment from "../Payment/Payment";

class App extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    const that = this;
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://web.squarecdn.com/v1/square.js";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      that.setState({
        loaded: true,
      });
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  // Focus on the datepicker when user clicks on 'Book Now' button in the Header
  bookNowClick = () => {
    var elem = document.getElementsByClassName("start-date");
    if (elem.length > 0) {
      elem[0].focus();
    }
  };

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Header callback={this.bookNowClick} />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/rv-list" component={RVList} exact />
            <Route path="/rv-details" component={RVDetails} exact />
            <Route path="/amenities" component={Amenities} exact />
            <Route path="/policies" component={Policies} exact />
            <Route path="/reviews" component={Reviews} exact />
            <Route path="/contact-us" component={Contact} exact />
            {this.state.loaded && (
              <Route path="/payment" exact component={Payment} />
            )}
          </Switch>
          <Footer />
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;