import React, { useState } from "react";
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PaymentForm from "../elements/PaymentForm/PaymentForm";
import Delivery from "../elements/Delivery/Delivery";
import "./Payment.css";

const Payment = (props) => {
  const [cost, setCost] = useState("");

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return month + "/" + day + "/" + year;
  };

  const startDate = formatDate(props.location.state.startDate);
  const endDate = formatDate(props.location.state.endDate);

  const [options, setOptions] = useState(
    new Array(props.location.state.rvData.options.length)
  );

  const [success, setSuccess] = useState();

  const [price, setPrice] = useState(
    +props.location.state.rvData.price *
      (Math.floor(props.location.state.endDate.getTime() / 1000 / 3600 / 24) -
        Math.floor(
          props.location.state.startDate.getTime() / 1000 / 3600 / 24
        ))
  );

  const [destination, setDestination] = useState();
  const [deliveryCost, setDeliveryCost] = useState();
  const [selectedDestination, setSelectedDestiantion] = useState();
  const [selectedDeliveryCost, setSelectedDeliveryCost] = useState();

	useEffect(() => {
		calcRequiredOptions(price);
	}, []);

	const calcRequiredOptions = (price) => {
		let selectedOptions = options;
	
		let firstOption =
		{
			name: "",
			cost: ""
		};
	
		firstOption.name = "Luxury Package (Linens, Towels, and Camping Gear)";
		firstOption.cost = "25";
	
		selectedOptions[0] = firstOption;
	
		let secondOption =
		{
			name: "",
			cost: ""
		}
	
		secondOption.name = "Convenience Package (Cleaning, and Propane)";
		secondOption.cost = "50";
	
		selectedOptions[1] = secondOption;
	
		setPrice(price + +"75");	
		setOptions(selectedOptions);
	}

  const changePrice = (event, optionPrice, name, i) => {
    let selectedOptions = options;
    let option = {
      name: "",
      cost: ""
    };
        
    if (event.target.checked) 
    {
      if (name.includes("Delivery"))
      {
        if (deliveryCost != null)
        {
          option.name = destination;
          option.cost = deliveryCost;
                    
          selectedOptions[i] = option;
          setPrice(price + +deliveryCost);

          setSelectedDestiantion(destination);
          setSelectedDeliveryCost(deliveryCost);
        }
        else
        {
          event.target.checked = false;
        }
      }
      else
      {
        option.name = name;
        option.cost = optionPrice;

        selectedOptions[i] = option;
        setPrice(price + +optionPrice);
      }
    } 
    else 
    {
      if (name.includes("Delivery"))
      {
        if (deliveryCost != null)
        {
          selectedOptions[i] = null;
          setPrice(price - +selectedDeliveryCost);
        }
        else
        {
          event.target.checked = false;
        }
      }
      else
      {
        selectedOptions[i] = null;
        setPrice(price - +optionPrice);
      }
    }

    setOptions(selectedOptions);
  };

  function changeDeliveryCost(destination, deliveryCost)
  {
    setDestination('Truck Delivery: ' + destination);
    setDeliveryCost(deliveryCost);
  };

  return !success ? (
    
    <div className="payment-wrapper boxed-content">
      <div className="rv-info-wrapper">
        <div className="rv-info">
          <h1>{props.location.state.rvData.name}</h1>
          <div className="item">
            Start date: <span>{startDate}</span>
          </div>
          <div className="item">
            End date: <span>{endDate}</span>
          </div>
          <div className="item">
            Price per night: <span>${props.location.state.rvData.price}</span>
          </div>
        </div>
        <img className="rv-image" src={props.location.state.rvData.images[0]} />
      </div>
      <div className="additional">
			<h3>Required Add Ons</h3>
        {props.location.state.rvData.options ? (
          <div className="select-wrapper">
            <div className="options">
              {props.location.state.rvData.requiredOptions.map((elem, i) => {
                return (
                  <div className="option-item" key={i}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      name="options"
                      value={elem.price}
                      checked={true}
                    />
                    <div className="option-name">
                      {(elem.name.includes("Delivery") ? ((selectedDestination) ? selectedDestination : "Truck Delivery: (Enter Delivery Address Below)") : (elem.name))} {(elem.name.includes("Delivery") ? (selectedDeliveryCost) && <span>${selectedDeliveryCost}</span> : <span>${elem.price}</span>)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <h3>Add Ons</h3>
        {props.location.state.rvData.options ? (
          <div className="select-wrapper">
            <div className="options">
              {props.location.state.rvData.options.map((elem, i) => {
                return (
                  <div className="option-item" key={i}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      name="options"
                      value={elem.price}
                      onChange={(e) => changePrice(e, elem.price, elem.name, i + 2)}
                    />
                    <div className="option-name">
                      {(elem.name.includes("Delivery") ? ((selectedDestination) ? selectedDestination : "Truck Delivery: (Enter Delivery Address Below)") : (elem.name))} {(elem.name.includes("Delivery") ? (selectedDeliveryCost) && <span>${selectedDeliveryCost}</span> : <span>${elem.price}</span>)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <Delivery payment={true} onChangeDeliveryCost = {changeDeliveryCost}/>
      </div>
      <div className="total-price">
        Total: <span>${price.toFixed(2)}</span>
      </div>
      <h1 className="payment-title">Payment</h1>
      {(success == null || !success) && (
        <PaymentForm
          paymentForm={props.paymentForm}
          price={price.toFixed(2)}
          id={props.location.state.rvData.id}
          startDate={props.location.state.startDate}
          endDate={props.location.state.endDate}
          options={options}
          success={(r) => setSuccess(r)}
          rvName={props.location.state.rvData.name}
        />
      )}

      {success == false && (
        <div className="payment-error">
          There was an error. Please check your card credentials and try again.
        </div>
      )}
    </div>
  ) : (
    <div className="success-wrapper boxed-content">
      <img src="./images/checkmark.svg" />
      <h1>Payment successful!</h1>
      <div className="success-text">
        Thank you for renting from NH RV Rental. You account has been charged and your transaction is successful.
        We will contact you shortly.<br />
        <NavLink to="/">Go to Homepage</NavLink>
      </div>
    </div>
  );
};

export default Payment;