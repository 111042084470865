import React, { Component } from "react";
import "./Amenities.css";
import ThreeColGrid from "../elements/ThreeColGrid/ThreeColGrid";
import CalendarFields from "../elements/CalendarFields/CalendarFields";
import Delivery from "../elements/Delivery/Delivery";
import Banner from "../elements/Banner/Banner";
import { render } from "@testing-library/react";

class Amenities extends Component {
  state = {
    amenities: null,
    addOns: null,
  };

  componentDidMount() {
    fetch("https://nhrvrentalapi.nhrvrental.com/get-amenities", {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        this.setState({
          amenities: findresponse.amenities,
          addOns: findresponse.add_ons,
        });
      });
  }

  // We are using 'type' parameter to check if it's Amenities data or Add Ons data because of styling.
  renderBox = (data, type) => {
    return (
      <div
        className={
          "box " + (type == "amenities" ? "amenities-box" : "add-ons-box")
        }
      >
        {data.map((element, i) => {
          return (
            <div className="box-content" key={i}>
              <h2>{element.name}</h2>
              {type != "amenities" ? <span>-</span> : null}
              <p className="text">{element.description} {type != 'amenities' && <span className="price">{element.price == 0 ? 'Calculated' : '$' + element.price}</span>}</p>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="bg-image-wrapper amenities-wrapper">
        <div className="overlay"></div>
        <img className="bg-image" src="./images/amenities-bg.png" />
        <div className="bg-image-content boxed-content">
          <h1>AMENITIES</h1>
          {this.state.amenities
            ? this.renderBox(this.state.amenities, "amenities")
            : null}
          <h1>Add Ons</h1>
          {this.state.addOns
            ? this.renderBox(this.state.addOns, "add-ons")
            : null}
          <Delivery payment={false} onChangeDeliveryCost = {null}/>
          <Banner footer={true}/>
        </div>
      </div>
    );
  }
}

export default Amenities;
