import React from "react";

import CalendarFields from "../CalendarFields/CalendarFields";
import "./Banner.css";

const Banner = (props) => {
  let bgImage = "";

  if (props.footer) {
    if (props.amenities) {
      bgImage = "./images/amenities-bg.png";
    } else {
      bgImage = "./images/banner-image-footer.jpg";
    }
  } else {
    bgImage = "./images/banner-image.png";
  }

  return (
    <div
      className={
        "banner " +
        (props.footer ? "banner-footer" : "hero") +
        (props.amenities ? " amenities-banner" : "")
      }
      style={{ backgroundImage: !props.footer && `url('${bgImage}')` }}
    >
      <div className="banner-content boxed-content">
        {props.footer ? (
          <h1 className="banner-title">BOOK YOUR ADVENTURE TODAY</h1>
        ) : (
          <h1 className="banner-title">Check available RVs</h1>
        )}
        <CalendarFields />
      </div>
    </div>
  );
};

export default Banner;
