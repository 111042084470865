import React, { useState } from "react";
import SlickSlider from "react-slick";
import { SRLWrapper } from "simple-react-lightbox";
import "./Slider.css";

const Slider = (props) => {
  let slider;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidestoShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <SRLWrapper>
        <SlickSlider ref={(s) => (slider = s)} {...settings}>
          {props.images.map((element, i) => {
            return <img src={element} key={i} className="slide-image" />;
          })}
        </SlickSlider>
      </SRLWrapper>

      <div
        className={
          "images " + (props.images.length > 2 ? "more-than" : "less-than")
        }
      >
        {props.images.map((element, i) => {
          return (
            <img
              key={i}
              className="rv-image"
              src={element}
              onClick={() => {
                slider.slickGoTo(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
