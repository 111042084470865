import React, { Component } from "react";
import "./Policies.css";
import ThreeColGrid from "../elements/ThreeColGrid/ThreeColGrid";
import CalendarFields from "../elements/CalendarFields/CalendarFields";
import Banner from "../elements/Banner/Banner";
import { render } from "@testing-library/react";

class Policies extends Component {
  state = {
    policies: null,
    faqs: null,
  };

  componentDidMount() {
    fetch("https://nhrvrentalapi.nhrvrental.com/get-policies", {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((findresponse) => {
        this.setState({
          policies: findresponse.policies,
          faqs: findresponse.faqs,
        });
      });
  }

  renderBox = (data, type) => {
    return (
      <div
        className="box policies-box"
      >
        {data.map((element, i) => {
          return (
            <div className="box-content" key={i}>
              <h2>{element.name}</h2>
              <p className="text">{element.description}</p>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <div className="bg-image-wrapper policies-wrapper">
        <div className="overlay"></div>
        <img className="bg-image" src="./images/policies-bg.png" />
        <div className="bg-image-content boxed-content">
          <h1>POLICIES</h1>
          {this.state.policies
            ? this.renderBox(this.state.policies, "policies")
            : null}
          <h1>FAQS</h1>
          {this.state.faqs
            ? this.renderBox(this.state.faqs, "faqs")
            : null}
          <Banner footer={true}/>
        </div>
      </div>
    );
  }
}

export default Policies;