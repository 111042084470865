import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import "./Header.css";

const Header = (props) => {
  const [menu, setMenu] = useState(false);

  const history = useHistory();

  const menuClick = (event) => {
    const menuChange = menu ? false : true;
    if (menuChange) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.height = "auto";
    }
    setMenu(menuChange);
  };

  const bookNowClick = () => {
    const hasField = document.getElementsByClassName("date-wrapper");
    if (hasField.length > 0) {
      props.callback();
    } else {
      history.push({
        pathname: "/",
        state: { redirected: true},
      });
    }
  };

  return (
    <div className="header-wrapper">
      {menu ? (
        <div className="menu-overlay">
          <div className="menu-content">
            <h2>MENU</h2>
            <Navigation callback={menuClick} />
            <div className="blue-btn" onClick={bookNowClick}>
              Book Now
            </div>
          </div>
        </div>
      ) : null}
      <div className="header boxed-content">
        <NavLink to="/" className="header-logo">
          <img src="./images/NHRV_WhiteBG.svg" />
        </NavLink>
        <div className="desk-nav">
          <Navigation />
          <div className="blue-btn" onClick={bookNowClick}>
            Book Now
          </div>
        </div>
        <div className="menu-btn">
          <div
            className={"nav-icon1" + (menu ? " open" : "")}
            onClick={(e) => menuClick(e)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
