import React, { useState } from "react";
import "./UserPaymentInfo.css";

const UserPaymentInfo = (props) => {

  

  return (
    <div className="user-payment-info-wrapper">
      <div className="two-col">
        <div className="field-wrapper">
          <label htmlFor="firstName">First Name</label>
          <input
            className="contact-field"
            type="text"
            id="firstName"
            name="firstName"
            onChange={(event) => props.setFirstName(event.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <label htmlFor="lastName">Last Name</label>
          <input
            className="contact-field"
            type="text"
            id="lastName"
            name="lastName"
            onChange={(event) => props.setLastName(event.target.value)}
          />
        </div>
      </div>
      <div className="two-col">
        <div className="field-wrapper">
          <label htmlFor="phone">Phone number</label>
          <input
            className="contact-field"
            type="text"
            id="phone"
            name="phone"
            onChange={(event) => props.setPhone(event.target.value)}
            onKeyUp={() => {props.checkPhone(); props.checkForm()}}
          />
        </div>
        <div className="field-wrapper">
          <label htmlFor="email">Email</label>
          <input
            className="contact-field"
            type="email"
            id="email"
            name="email"
            onChange={(event) => props.setEmail(event.target.value)}
            onKeyUp={() => {props.checkEmail(); props.checkForm()}}
          />
        </div>
      </div>
    </div>
  );
};

export default UserPaymentInfo;
