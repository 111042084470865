import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarFields.css";
import { useHistory } from "react-router-dom";

const CalendarFields = (props) => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  const [result, setResult] = useState(null);


  const history = useHistory();

  const checkForRv = () => {
    if (startDate == null || endDate == null) {
      console.log("pick the dates");
      return;
    }

    fetch("https://nhrvrentalapi.nhrvrental.com/check-avail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rv: {
          id: props.id,
          start: new Date(startDate),
          end: new Date(endDate),
        },
      }),
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        props.callback(r.isAvail, startDate, endDate);
      });
  };

  const findAllAvail = () => {
    if (startDate == null || endDate == null) {
      console.log("pick the dates");
      return;
    }

    fetch("https://nhrvrentalapi.nhrvrental.com/find", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rv: {
          start: startDate,
          end: endDate,
        },
      }),
    })
      .then((r) => {
        return r.json();
      })
      .then((res) => {
        if(res.avail.length < 1) {
          setResult(false);
          return;
        }
        setResult(true);
        history.push({
          pathname: "/rv-list",
          state: {idList: res, start: startDate, end: endDate}
        });
      });
  };

  return (
    <div className={"fields-wrapper " + (props.details ? "details" : "")}>
      <div className="date-wrapper">
        <DatePicker
          selected={startDate}
          onChange={(date) => {setStartDate(date); props.availCallback && props.availCallback(); setResult(null)}}
          placeholderText="From"
          className="start-date"
          minDate={new Date()}
        />
        <img className="calendar-img" src="./images/calendar.svg" />
      </div>
      <div className="date-wrapper">
        <DatePicker
          selected={endDate}
          onChange={(date) => {setEndDate(date); {props.availCallback && props.availCallback(); setResult(null)}}}
          placeholderText="To"
          minDate={(startDate ?  new Date(startDate).addDays(1): new Date())}
        />
        <img className="calendar-img" src="./images/calendar.svg" />
      </div>
      <div className="find-btn" onClick={props.details ? checkForRv : findAllAvail}>
        {props.details ? "Check" : "FIND RVs"}
      </div>
      {(!result && result != null) && <div className="rv-msg">
        Please contact us for availability.
      </div>}
    </div>
  );
};

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export default CalendarFields;
