import React, { useState } from "react";
import CalendarFields from "../elements/CalendarFields/CalendarFields";
import { NavLink } from "react-router-dom";
import "./RV-Details.css";
import Slider from "../Slider/Slider";

const RVDetails = (props) => {
  const { data } = props.location.state;

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [mainImage, setMainImage] = useState(data.images[0]);

  const [available, setAvailable] = useState(null);

  const checkResult = (isAvail, start, end) => {
    isAvail ? setAvailable(true) : setAvailable(false);
    setStart(start);
    setEnd(end);
  };

  // When changing dates, reset availability status for new check
  const resetAvail = () => {
    setAvailable(null);
  }

  return (
    <div className="rv-details-wrapper">
      <div className="boxed-content rv-details">
        <div className="left">
          <Slider images={data.images} />
        </div>
        <div className="right">
          <h1 className="name">{data.name}</h1>
          <h3>About the RV</h3>
          <ul className="rv-description">
            {data.description.map((element, i) => {
              return (
                <li key={i}>
                  <p>{element}</p>
                </li>
              );
            })}
          </ul>
          <h3 className="avail-title">Check availability:</h3>
          <CalendarFields
            details={true}
            callback={checkResult}
            id={data.id}
            startDate={data.startDate}
            endDate={data.endDate}
            availCallback={resetAvail}
          />
          {!available && available != null ? (
            <div className="status-msg-wrapper">
              <div className="status-msg">
                Not available for selected dates.
              </div>
            </div>
          ) : available && available != null ? (
              <NavLink
              className="blue-btn available"
                to={{
                  pathname: "/payment",
                  state: { rvData: data, startDate: start, endDate: end },
                }}
              >
                Available! Click to Book
              </NavLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RVDetails;
